import React from 'react';
import { Input } from 'antd';

class OrderForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
            name:"",
            phone:"",
            email:"",
            date:"",
            shop:"",
            address_1:"",
            address_2:"",
            district:"",
            city:"",
            zip:"",
            country:"",
            query:""
        }
    }
    render(){
        return (
            <React.Fragment>
                
            </React.Fragment>
        )
    }
}
export default OrderForm;