import React from 'react'
import {Menu, Layout, Icon} from 'antd'
import '../css/navbar.css'
import Logo from './Logo'


class NavBar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            expanded: false
        }
    }
    // showMenu = ()=>{this.setState({expanded:true})}
    // hideMenu = ()=>{this.setState({expanded:false})}
    render(){
        return(
            <React.Fragment>
                <div className="logo">
                    <Logo className="logo_image"/>
                    <div className="logo_text">
                            KS BUSINESS GROUP
                    </div>
                    
                </div>
                <React.Fragment>
                    <div className="hamburger">
                        <Icon 
                            type="menu" 
                            style={{visibility:this.props.isMobile()?"visible":"hidden", float:'right'}}
                            onClick={()=>{this.setState({expanded:!this.state.expanded})}}/>
                    </div>
                    <Menu
                        theme="light"
                        mode={this.props.isMobile()?"vertical":"horizontal"}
                        defaultSelectedKeys={["home"]}
                        className="menu"
                        className="maincontent"
                        style={{visibility:(!this.props.isMobile()||this.state.expanded)?"visible":"hidden", display:(!this.props.isMobile()||this.state.expanded)?"block":"none", width: "100%"}}
                    >
                        <Menu.Item onClick={this.hideMenu} key="Home"><a href="#home"></a>Home</Menu.Item>
                        <Menu.Item onClick={this.hideMenu} key="Philosophy"><a href="#phil"></a>Philosophy</Menu.Item>
                        <Menu.Item onClick={this.hideMenu} key="About Us"><a href="#about"></a>About Us</Menu.Item>
                        <Menu.Item onClick={this.hideMenu} key="Locate Us"><a href="#locate"></a>Locate Us</Menu.Item>
                        <Menu.Item onClick={this.hideMenu} key="Talk to Us"><a href="#talk"></a>Talk to Us</Menu.Item>

                    </Menu>
                </React.Fragment>
            </React.Fragment>
        )
    }
}

export default NavBar;