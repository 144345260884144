import React, {useState, useEffect} from 'react';
import NavBar from './components/Navbar';
import {Layout, Divider, Row, Col, Card} from 'antd'
import throttle from 'lodash.throttle'
import ScrollAnim from 'rc-scroll-anim';
import './css/App.css'
import Title from 'antd/lib/typography/Title';
import OrderForm from './components/OrderForm';
const {Header, Footer, Content} = Layout;


const ScrollParallax = ScrollAnim.Parallax;

function App() {
  const [viewWidth, setViewWidth] = useState(0)
  const mobileBreakpoint = 830
  const throttleViewPorttime = 520
  const isMobile = ()=> {return viewWidth<=mobileBreakpoint}

  useEffect(() => {
    setViewWidth(window.innerWidth);
    const throttledSetWidth = throttle(()=>{setViewWidth(window.innerWidth)}, throttleViewPorttime)
    window.addEventListener('resize', throttledSetWidth)
    return () => {
      window.removeEventListener('resize', throttledSetWidth)
    };
  }, [])

  return (
    <div className="App">
      <Header className="header">
        <NavBar isMobile={isMobile}/>
      </Header>
      
      <div className="maincontent" id="home">
        <div className="kethasai">
              <div style={{width:isMobile()?"100%":"65%"}} className="kethasai_title"><mark>Kethaki Sai Business Group</mark></div>
              <div className="kethasai_subtext"><mark>Delivering quality products since 1993</mark></div>
        </div>
        
        <div className="animimage_container">
          <ScrollParallax 
          animation={{ blur: '10px', playScale: [0.8, 0.9], opacity: 1, color: '#fff', scaleX:1.25, scaleY:1.25 }}
          style={{ filter: 'blur(5px)', opacity: 1, color: '#fff'}}>
            <img className="animimage" src="/images/first.webp"/>
          </ScrollParallax>
        </div>

        <div className="section different centeredtext" id="about">
          <Title>How are we different? </Title>
          <p>We deliver hand-picked, cleaned and high-quality goods. Delivering quality products has always been our top priority and we have been doing it since 1993.</p>
          <Divider style={{fontSize:80}} type="vertical"/>
        </div>

        <div className="section">
          <Row gutter={30}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="subsection_title" style={{marginTop:isMobile()?"5%":"30%"}}>Stores</div>
              <div className="subsection_text">
                We predominantly operate through two stores. One in an old fashioned way and the other keeping up to the trend.
              </div>
              <div className="subsection_text">
              <Divider style={{fontSize:80}} type="vertical"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="subsection_image">
              <img src="/images/grains.webp"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="subsection_image">
              <img src="/images/racks.webp"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row gutter={30}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="subsection_image">
              <img src="/images/seeds.webp"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="subsection_title" style={{marginTop:isMobile()?"5%":"30%"}}>Distribution Network</div>
              <div className="subsection_text">
                Along with the supermarket chain, we are also the authorized distributors for some FMCG companies
              </div>
              <div className="subsection_text">
              <Divider style={{fontSize:80}} type="vertical"/>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} id="phil">
              <div className="subsection_title">Philosophy</div>
              <div className="subsection_text">
                We are always committed to delivering quality. We not only deliver goods of high quality but we also make sure that all the lentils, cereals and its products are handpicked and cleaned and packed hygienically under our in-house brand. Our in-house brand is well known and holds high regard for quality throughout the town. 
              </div>
              <div className="subsection_text">
              <Divider style={{fontSize:80}} type="vertical"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row gutter={30}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="subsection_image">
              <img src="/images/flour.webp"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="subsection_text" style={{marginTop:isMobile()?"5%":"10%"}}>
                To address the predominant issue on the quality of flours, Our team makes sure that high-quality lentils are chosen, dried and then ground to flour. Along with the other products, we deliver it under our private label which marks quality assurance.
              </div>
              <div className="subsection_text">
              <Divider style={{fontSize:80}} type="vertical"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row gutter={30}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{marginTop:isMobile()?"1%":"5%"}}>
              <div className="subsection_text">
                Our Supermarkets are very much known for the availability of freshly ground spices. Our team makes sure that these are available to the customers on a regular and need on need basis.
              </div>
              <div className="subsection_text">
              <Divider style={{fontSize:80}} type="vertical"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="subsection_image">
              <img src="/images/spices.webp"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section" id="locate">
          <Row gutter={30}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                cover={
                  <img src="/images/banana.jpg"/>
                }
              >
              <div className="card">
                <div className="card_title">KETHAKI SAI SUPER BAZAR</div>
                <div className="card_address">
                ADDRESS: NEAR ACHARYA DEGREE COLLEGE, MAIN ROAD, ZAHIRABAD, 502220 PHONE-8897329776
                </div>
                <div className="card_timings">
                TIMINGS 8:00 AM - 10:30 PM
                </div>
              </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                cover={
                  <img src="/images/vege_grains.jpg"/>
                }
              >
              <div className="card">
                <div className="card_title">KETHAKI STORES</div>
                <div className="card_address">
                  HANUMAN MANDIR ROAD, ZAHIRABAD 502220
                </div>
                <div className="card_timings">
                TIMINGS 9:00 AM - 9:30 PM
                </div>
              </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                cover={
                  <img src="/images/bag.jpg"/>
                }
              >
              <div className="card">
                <div className="card_title">KETHAKI SAI JOBBERS</div>
                <div className="card_address">
                ADDRESS: KETHAKI SAI NILAYAM, BASAVANAGAR COLONY, ZAHIRABAD, 502220
                </div>
                <div className="card_timings">
                TIMINGS 10:00 AM - 5:00 PM
                </div>
              </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <OrderForm/>
        </div>
      </div>
      

      
      <Footer>
        <div className="maincontent">
          <div className="copyright">
            © 1993 - {new Date(Date.now()).getFullYear()} Kethaki Sai Group 
          </div>
        </div>
      </Footer>
    </div>
  );
}

export default App;
