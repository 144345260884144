import React from 'react'

function Logo(props){
    return (
        <div className={props.className}>
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="35 35 130 130" viewBox="35 35 130 130" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
        <g>
            <path d="M35 35v130h130V35H35zm3.5 126.5V93.1h44.1v24.2h24.2v44.1H38.5zm68.4-47.6H86.1V93.1h20.7v20.8zm3.5 47.6V89.6H38.5V67.3h94.2v94.2h-22.3zm51.1 0h-25.3V63.8H38.5V38.5h123v123z"></path>
        </g>
    </svg>    
        </div>
    )
}

export default Logo;